.container {
  position: relative;
  width: 100%;
  background: url('https://techbenextjs.vercel.app/assets/img/bg/blogBg1_1.png');
}

.content {
  padding: 5rem var(--content-padding);
}

.content h3 {
  color: var(--theme-color);
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 20px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.card {
  padding: 24px;
  border: 1px solid var(--smoke-color);
  background: var(--white-color);
  border-radius: 16px;
}

.card img {
  width: 322px;
  height: 216px;
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid var(--smoke-color);
}

.cardBody {
  max-width: 322px;
}

.cardBody h3 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  color: var(--theme-color);
  margin-bottom: 5px;
  min-height: 80px;
}

.cardBody a {
  text-decoration: none;
}

.cardAuthor {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.cardTags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;
}

.cardTags span {
  font-size: 11px;
  color: var(--theme-color);
  background-color: var(--smoke-color);
  padding: 8px;
  border-radius: 16px;
}

.cardMore {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 28px;
}

.cardMore i {
  margin-left: 5px;
  font-size: 14px;
}