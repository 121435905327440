.container {
  position: relative;
  width: 100%;
}

.content {
  padding: 5rem var(--content-padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0;
}

.calendar h3 {
  color: var(--theme-color);
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: -50px;
}

.separator {
  border-left: 2px solid var(--theme-color);
  padding: 50px
}

.form {
  flex-grow: 1;
}

.form h3 {
  color: var(--theme-color);
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 25px;
}

.inputBox {
  font-family: var(--body-font);
  margin-bottom: 15px;
}

.inputBox label {
  font-size: 14px;
  display: block;
}

.inputBox input, textarea {
  width: 100%;
  margin-top: 4px;
  border: none;
  outline: none;
  min-height: 40px;
  font-family: inherit;
  font-size: 16px;
  color: var(--body-color);
  background: var(--smoke-color);
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}

.form textarea {
  padding-top: 8px;
  min-height: 110px;
}

.button {
  border: none;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  gap: 1rem;
  width: 12rem;
  line-height: 1;
  padding: 1rem 1.5rem;
  background: var(--theme-color);
  color: var(--white-color);
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
}