.container {
    position: absolute;
    cursor: pointer;
    bottom: -20px;
    left: -28px + 50%;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: linear-gradient(90deg,var(--theme-color),var(--theme-color2) 123.82%);
  
    i {
      margin-top: 20px;
      color: var(--white-color);
    }
  }

.container:hover {
  background: var(--theme-color2);
}