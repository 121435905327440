@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

:root {
  --content-padding: 14rem;

  --body-font: "Plus Jakarta Sans", sans-serif;
  --body-color: #726b7d;

  --title-font: "Plus Jakarta Sans", sans-serif;
  --theme-color: #00164E;
  --theme-color2: #2282C2;

  --theme-gradient: linear-gradient(to right, var(--theme-color), var(--theme-color2));

  --smoke-color: #f6f5fd;
  --white-color: #FFFFFF;
  --black-color: #000000;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

header, section, nav {
  display: block;
}

body {
  scroll-behavior: smooth;
  font-family: var(--body-font);
  color: var(--body-color);
  background-color: var(--white-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-text-fill-color: var(--body-color);
  -webkit-box-shadow: 0 0 0 30px var(--smoke-color) inset !important;
}