.container {
  position: relative;
  width: 100%;
  margin-top: 120px;
}

.content {
  padding: 0 var(--content-padding);
}

.service {
  padding-top: 150px;
  display: flex;
  gap: 30px;
  width: 100%;
  align-items: center;
}

.service .left {
  flex: 1;
}

.service .right {
  flex: 1;
}

.service .right img {
  filter: grayscale(1);
}

.service h1 {
  color: var(--theme-color);
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
}

.service p {
  line-height: 28px;
  text-align: justify;
  margin-bottom: 25px;
}

.service img {
  max-width: 550px;
  border-radius: 8px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
}

.service ul {
  margin-top: 20px;
  list-style-type: none;
  font-weight: 500;
  font-size: 18px;
  color: var(--theme-color);

  & i {
    color: var(--theme-color2);
    margin-right: 5px;
  }
}

.serviceButton {
  margin-top: 30px;
}