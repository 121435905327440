
.service-section {
  top: -120px;
  margin-bottom: -120px;
}

.detailed-service-section {
  margin-bottom: 100px;
}

.contact-section {
  margin-bottom: 150px;
}