.container {
  position: relative;
  height: 100vh;
  width: 100%;
}

.absolute {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.image {
  background-image: url('https://techbenextjs.vercel.app/assets/img/hero/hero-bg1_1.png');
}

.overlay {
  background: linear-gradient(to top left, var(--theme-color2) 0%, var(--theme-color) 80%);
  opacity: .9;
}

.content {
  position: relative;
  padding: 0 var(--content-padding);
  display: flex;
  align-items: center;
  color: var(--white-color);
}

.content h1 {
  font-size: 80px;
  font-weight: 800;
  height: 90px;
  line-height: 90px;
}

.content h2 {
  font-size: 18px;
  font-weight: 600;
  height: 32px;
  line-height: 32px;
  text-transform: uppercase;
}

.content p {
  font-size: 18px;
  font-weight: 500;
  height: 60px;
  line-height: 30px;
  max-width: 675px;
  margin-top: 24px;
  margin-bottom: 55px;
}