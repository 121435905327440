.container {
  color: var(--white-color);
  padding: 0 var(--content-padding);
  padding-top: 80px;
  padding-bottom: 80px;
  background: var(--theme-color);
}

.content {
  display: flex;
  justify-content: space-between;
}

.content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-transform: capitalize;
  padding-bottom: 25px;
}

.logo {
  max-width: 100%;
}

.logo img {
  cursor: pointer;
  margin-bottom: 20px;
}

.logo p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 30px;
  max-width: 300px;
}

.contact {
  & ul {
    list-style-type: none;
  }
  & li {
    margin-bottom: 20px;
  }
  & i {
    margin-right: 15px;
  }
  & img {
    max-width: 30px;
    border-radius: 4px;
    background: var(--white-color);
  }
  & a {
    color: var(--white-color);
    text-decoration: none;
  }
}

.contact li:hover, a:hover {
  cursor: pointer;
  color: var(--theme-color2);
}

.copyright {
  margin-top: 0px;
  text-align: left;
}

.copyright i {
  margin-right: 10px;
}