.container {
  position: relative;
  width: 100%;
}

.content {
  padding: 0 var(--content-padding);
}

.content h3 {
  color: var(--theme-color);
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
}

.logos {
  background: url('https://techbenextjs.vercel.app/_next/image?url=%2Fassets%2Fimg%2Fbg%2FtestimonialBg1_1.png&w=1920&q=75');
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 300px;
  gap: 100px;
}

.logos img {
  width: 200px;
  filter: grayscale(0);
}