.container {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
}

.overlayBlocks {
  position: absolute;
  width: 100%;
  height: 200px;
  background: var(--white-color);
  clip-path: polygon(
    /* counter clockwise outer region */
    0 0, 0 100%, 100% 100%, 100% 0, 0 0,
    /* clockwise inner region */
    0 0, 100% 0, 80% 100%, 20% 100%, 0 0
  );
}

.content {
  position: relative;
  padding: 0 var(--content-padding);
  display: flex;
  justify-content: space-between;
}

.card {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 16px;
  padding: 36px;
  background: var(--white-color);
  border-radius: 8px;
  height: 325px;
  width: 265px;
  box-shadow: 0 4px 25px 0 rgba(56, 75, 255, .08);
  box-sizing: border-box;
}

.card h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color);
  line-height: 32px;
}

.card p {
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 28px;
}

.cardIconContainer {
  position: relative;
  width: 80px;
  padding: 15px;
}

.cardIconOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--theme-color);
  opacity: 0.1;
}