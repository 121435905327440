.container {
  padding: 0 var(--content-padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white-color);
}

.logo {
  cursor: pointer;
  max-width: 16rem;
}

.navbar ul {
  display: flex;
  gap: 3rem;
  list-style-type: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 26px;
  color: var(--black-color);  
}

.navbar ul li {
  cursor: pointer;
  position: relative;
  transition: .4s;
  padding-top: 2rem;
  margin-bottom: 1.7rem;
  padding-bottom: 0.3rem;
}

.navbar a {
  text-decoration: none;
  color: var(--black-color); 
}

.navbar ul li:hover {
  color: var(--theme-color2)
}

.navbar ul li::after {
  content: '';
  position: absolute;
  transform: scaleX(0);
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: var(--theme-gradient);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navbar ul li:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}