.container {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    gap: 1rem;
    width: 12rem;
    line-height: 1;
    padding: 1rem 1.5rem;
    height: 100%;
    background: var(--theme-color);
    color: var(--white-color);
    font-weight: 700;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    transform: rotate(0deg);
  }

  .container a:hover {
    color: var(--white-color);
  }
  
  .container:hover {
    background: var(--theme-color2);
  }